.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
:root {
  --breakpoint-sm: 600px;
  /* --theme-color-1: #008072; */
  --theme-color-1: #008060;
  --theme-color-1-hover: #00a279;
  /* --theme-color-1: #4DA0FF; */
  --theme-color-2: #e1e1e1;
  --theme-error: #D8000C;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}
input{
  -webkit-appearance: none;
  -moz-appearance: none;
}
.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}
.login-paper{
  max-width: 500px;
  margin: auto;
  padding: 40px;
  margin-top: 10vh;
}
@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}
.error-message{
  color: var(--theme-error);
}
.btn-primary {
  min-width: 230px;
  padding: 10px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 18px;
  border-radius: 25px;
  color: rgb(255, 255, 255);
  background-color: var(--theme-color-1);
  /* background-color: #00B78A; */
  /* background-color: #008060; */
  /* background-color: #4DA0FF; */
  max-width: 320px;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border: none;
  cursor: pointer;
  outline: none;
}
.btn-primary:hover{
  background-color: var(--theme-color-1-hover);
}
.item-content{
  width: 220px;
  height: 300px;
  background-color: var(--theme-color-2);
  border-radius: 10px;
  border: 2px solid transparent;
  padding: 10px;
  cursor: pointer;
}
.item-content:hover{
  color:var(--theme-color-1);
  border-color: var(--theme-color-1);
}
.form-builder{
  background-color: var(--theme-color-2);
  padding-top: 100px;
  min-height: 90vh;
}
.form-builder-paper{
  max-width: 550px;
  margin: auto;
  padding: 20px;
}
.form-header{
  margin-top: 30px;
  margin-bottom:30px;
  padding: 10px;
  width: 100%;
  position: relative;
}
.form-header:focus{
  outline: 2px solid var(--theme-color-1-hover);
  box-shadow: 0 0 30px #96f226;
  -webkit-box-shadow: 0 0 30px #96f226;
}
.form-fields{
  padding: 10px;
}
.form-add-field-button{
  border: 2px solid var(--theme-color-2);
  border-style: dashed;
  padding: 10px;
  cursor: pointer;
}
.form-add-field-button:hover{
  color:var(--theme-color-1);
  border-color: var(--theme-color-1);
}
.form-field-controls{
  position: absolute;
  transform: translateY(-50%);
  right: 40px;
  z-index: 2;
  width: 40px;
  background-color: transparent;
  display: none;
  top:50%;
  cursor: pointer;
}
.form-header:hover .form-field-controls{ 
  display:block;
  }
.form-submission{
  position: relative;
}
.form-submission:hover .form-field-controls{
  display: block;
}
.form-settings{
  background-color: var(--theme-color-2);
}
.form-settings-paper{
  padding: 20px;
  text-align: left;
  min-width: 500px;
  min-height: 500px;
  margin-top: 50px;
  max-width: 600px;
}
.select_wrapper{
    position: relative;
    border-radius: 4px;
    border-width: 1px;
    border-style: solid;
    padding: sp-spacing(unit-5x) sp-spacing(unit-5x) sp-spacing(unit-2x);
    width: 100%;
}
.custom_select{
    top: 0;
    left: 0;
    z-index: 10;
    line-height: 1.5;
    height: 100%;
    width: 100%;
}
.select_content{
    position: relative;
    width: 100%;
    display: flex;
}
.select_label {
    position: absolute;
    top: -50px;
    font-size: 20px;
    opacity: 0.7;
    transition: all 0.3s ease-in-out;
}
.selected_label {
    white-space: nowrap;
    overflow: hidden;
    padding-right: "10px";
    text-overflow: ellipsis;
}
.FormConstructorSubmit{
    font-size: 17px;
    border-radius: 3px;
    border: 0;
    color: rgb(255, 255, 255);
    background-color: var(--theme-color-1);
    font-weight:300;
    text-align: center;
    transition: all 0.2s;
    padding:0.3em 1.2em;
    cursor: pointer;
}
.FormConstructorSubmit:hover{
    background-color: var(--theme-color-1-hover);
}
.FormConstructorContainer{
    margin-bottom: 20px;
}
.FormConstructorSubmit:focus {
    outline: none;
    box-shadow: 0 0 0 4px #cbd6ee;
  }
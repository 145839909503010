.EntriesPaper{
    height: 100%;
    margin-top: 50px;
}
.EntriesHeader{
    width: 100%;
}
.Title{
    flex: 1 1 100%;
}
.FormTitle{
    text-align: left;
    margin-top: 40px;
    width: 100%;
}
.DashboardButton{
    padding: 10px;
    padding-left: 14px;
    padding-right: 14px;
    font-size: 18px;
    border-radius: 3px;
    color: rgb(255, 255, 255);
    background-color: var(--theme-color-1);
    /* background-color: #00B78A; */
    /* background-color: #008060; */
    /* background-color: #4DA0FF; */
    max-width: 320px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    border: none;
    cursor: pointer;
    outline: none;
}
.FormActionsContainer{
    text-align: right;
    right: 0;
}
.EntriesGrid{
}

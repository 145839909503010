.CardForm{
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    max-width: 250px;
    min-height: 200px;
}
.CardFormHeader{
   height: 50px;
   padding: 8px;
   font-size: 20px;
}
.CardFormActions{
    padding: 8px;
    text-align: left;
}
.CardMedia {
    padding-top: "56.25%"
}
